.mainPageParent{
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.headingTag{
    background-color: #2e466e !important;
    height: 50px;
    justify-content: center;
    align-items: center;
    flex: column;
    display: flex;
    margin-bottom: 1px;
}
.headingTagText{
    color: #E1F2FE;
}
.gaJdmQ {
    background: #2e466e !important;
    margin-bottom: 1px;
}.kvEdTd {
    color: white !important;
}
.dvDnbv {
    color: #E1F2FE !important;
}