.files-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.files-table th,
.files-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.file-row {
  cursor: pointer;
}

.file-row.expanded {
  background-color: #E1F2FE;
}

.download-button {
  padding: 5px 10px;
  background-color: #2e466e;
  color: white;
  border: none;
  cursor: pointer;
}

.download-button:hover {
  background-color: black;
}

.expanded-row {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #E1F2FE;
}

.submit-button {
  padding: 5px 10px;
  background-color: #2e466e;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #2e466e;
}

.noresumes{
  display: grid;
    justify-content: center;
    font-family: cursive;
  }