.needReferralBox{
	position: relative;
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    padding: 100px;
    background-color: #E1F2FE;
}
.label{
    font-weight: bold;
    display: block;
}
.popUpBox{
    padding: 100px;
    box-sizing: border-box;
}
.myButton {
	box-shadow:inset 0px 0px 15px 3px #23395e;
	background:linear-gradient(to bottom, #2e466e 5%, #415989 100%);
	background-color:#2e466e;
	border-radius:17px;
	border:1px solid #1f2f47;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	padding:6px 13px;
	text-decoration:none;
	text-shadow:0px 1px 0px #263666;
}
.myButton:hover {
	background:linear-gradient(to bottom, #415989 5%, #2e466e 100%);
	background-color:#415989;
}
.myButton:active {
	position:relative;
	top:1px;
}
.referral-form {
	display: flex;
	flex-direction: column;
	padding: 50px;
	background-color: #E1F2FE;
	height: inherit;
}
.referral-form__separator {
    border-top: 2px double #ccc;
    margin: 20px;
}
.referral-form__form {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.referral-form__label {
	font-weight: bold;
	margin-bottom: 10px;
	font-family: cursive;
	margin-left: 20%;
}
.referral-form__input {
	width: 65%;
	padding: 10px;
	margin-bottom: 20px;
	border: 2px solid #ccc;
	border-radius: 5px;
	margin-left: 20%;
}
.referral-form__file-upload {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	margin-left: 20%;
}
.referral-form__file-input {
	display: none;
}
.referral-form__file-button {
	padding: 10px;
	background-color: #23395e;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	margin-left: 10px;
}
.referral-form__submit-section {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
}
.referral-form__submit-button {
	padding: 10px 20px;
	background-color: #23395e;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	width: 200px
}
        